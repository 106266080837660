import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import loadable from '@loadable/component';
import { UserConsumer } from '../../../components/context/userContext';
import BlueConicPremiumPaywall from '../../../components/BlueConicPremiumPaywall/BlueConicPremiumPaywall';

const Social = loadable(() => import('../../../components/social/social'));

/* eslint no-plusplus: 0 */
/* eslint no-restricted-syntax: 0 */
/* eslint no-return-assign: 0 */
/* eslint no-param-reassign: 0 */
/* eslint func-names: 0 */
/* eslint jsx-a11y/alt-text: 0 */
/* eslint no-constant-condition: 0 */
/* eslint react/no-did-mount-set-state: 0 */

export default class ListArticle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      social: true,
      openAccordion:
        this.props.posts[0].customFields.list_article.copy_display_style ===
          'accordion' &&
        this.props.posts[0].customFields.list_article.open_first_accordion
          ? 0
          : null,
      openPanel: 0,
      openMobileShare: false,
      currentCerosHeight: 0,
      isMobile: false,
      currentCerosEmbed: `<div style='position: relative;width: auto;padding: 0 0 58.59%;height: 0;top: 0;left: 0;bottom: 0;right: 0;margin: 0;border: 0 none' id="experience-5f5fad762dd99" data-aspectRatio="1.70666667"><iframe allowfullscreen src='//view.ceros.com/inc/ibd2020-topart-desktop?heightOverride=1125' style='position: absolute;top: 0;left: 0;bottom: 0;right: 0;margin: 0;padding: 0;border: 0 none;height: 1px;width: 1px;min-height: 100%;min-width: 100%' frameborder='0' class='ceros-experience' title='ibd2020-topart-desktop' scrolling='no'></iframe></div><script type="text/javascript" src="//view.ceros.com/scroll-proxy.min.js" data-ceros-origin-domains="view.ceros.com"></script>`
    };

    this.accordionContent = [];

    // this.queerButton =
    //   'https://images.fastcompany.net/image/upload/v1587587567/fc/q50_button_km78sn.svg';
    // this.WCIarrow =
    //   'https://images.fastcompany.net/image/upload/v1587573587/fc/arrow_iq7i6n.svg';
    // this.WCIheader =
    //   'https://images.fastcompany.net/image/upload/v1587594921/fc/wci.top.art-12_ca7chy.svg';

    // Functions
    this.formattedListNumber = this.formattedListNumber.bind(this);
    this.handleAccordion = this.handleAccordion.bind(this);
    this.handlePanels = this.handlePanels.bind(this);
    this.toggleMobileShare = this.toggleMobileShare.bind(this);
    this.getDistance = this.getDistance.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.mobileEmbed = `<div style='position: relative;width: auto;padding: 0 0 100%;height: 0;top: 0;left: 0;bottom: 0;right: 0;margin: 0;border: 0 none' id="experience-5f6a33ce58d0f" data-aspectRatio="1"><iframe allowfullscreen src='//view.ceros.com/inc/ibd2020-topart-mobile?heightOverride=355' style='position: absolute;top: 0;left: 0;bottom: 0;right: 0;margin: 0;padding: 0;border: 0 none;height: 1px;width: 1px;min-height: 100%;min-width: 100%' frameborder='0' class='ceros-experience' title='ibd2020-topart-mobile' scrolling='no'></iframe></div><script type="text/javascript" src="//view.ceros.com/scroll-proxy.min.js" data-ceros-origin-domains="view.ceros.com"></script>`;
    this.desktopEmbed = `<div style='position: relative;width: auto;padding: 0 0 58.59%;height: 0;top: 0;left: 0;bottom: 0;right: 0;margin: 0;border: 0 none' id="experience-5f5fad762dd99" data-aspectRatio="1.70666667"><iframe allowfullscreen src='//view.ceros.com/inc/ibd2020-topart-desktop?heightOverride=1125' style='position: absolute;top: 0;left: 0;bottom: 0;right: 0;margin: 0;padding: 0;border: 0 none;height: 1px;width: 1px;min-height: 100%;min-width: 100%' frameborder='0' class='ceros-experience' title='ibd2020-topart-desktop' scrolling='no'></iframe></div><script type="text/javascript" src="//view.ceros.com/scroll-proxy.min.js" data-ceros-origin-domains="view.ceros.com"></script>`;

    // Refs
    this.panels = React.createRef();
  }

  componentDidMount() {
    this.setState({
      currentCerosEmbed:
        window.innerWidth < 767
          ? `<div style='position: relative;width: auto;padding: 0 0 100%;height: 0;top: 0;left: 0;bottom: 0;right: 0;margin: 0;border: 0 none' id="experience-5f6a33ce58d0f" data-aspectRatio="1"><iframe allowfullscreen src='//view.ceros.com/inc/ibd2020-topart-mobile?heightOverride=355' style='position: absolute;top: 0;left: 0;bottom: 0;right: 0;margin: 0;padding: 0;border: 0 none;height: 1px;width: 1px;min-height: 100%;min-width: 100%' frameborder='0' class='ceros-experience' title='ibd2020-topart-mobile' scrolling='no'></iframe></div><script type="text/javascript" src="//view.ceros.com/scroll-proxy.min.js" data-ceros-origin-domains="view.ceros.com"></script>`
          : `<div style='position: relative;width: auto;padding: 0 0 58.59%;height: 0;top: 0;left: 0;bottom: 0;right: 0;margin: 0;border: 0 none' id="experience-5f5fad762dd99" data-aspectRatio="1.70666667"><iframe allowfullscreen src='//view.ceros.com/inc/ibd2020-topart-desktop?heightOverride=1125' style='position: absolute;top: 0;left: 0;bottom: 0;right: 0;margin: 0;padding: 0;border: 0 none;height: 1px;width: 1px;min-height: 100%;min-width: 100%' frameborder='0' class='ceros-experience' title='ibd2020-topart-desktop' scrolling='no'></iframe></div><script type="text/javascript" src="//view.ceros.com/scroll-proxy.min.js" data-ceros-origin-domains="view.ceros.com"></script>`
    });
    window.addEventListener('resize', this.handleResize);
  }

  getDistance(elem) {
    let location = 0;

    if (elem.offsetParent) {
      do {
        location += elem.offsetTop;
        elem = elem.offsetParent;
      } while (elem);
    }

    return location >= 0 ? location : 0;
  }

  formattedListNumber(options, number) {
    const i = number + 1;

    switch (options) {
      case 'one':
        return i;
      case 'two':
        return number < 9 ? `0${i}` : i;
      case 'one_hash':
        return `#${i}`;
      case 'two_hash':
        return number < 10 ? `#0${i}` : `#${i}`;
      case 'one_no':
        return `No. ${i}`;
      case 'two_no':
        return number < 10 ? `No. 0${i}` : `No. ${i}`;
      default:
        return i;
    }
  }

  handleAccordion(i) {
    this.setState({ openAccordion: this.state.openAccordion !== i ? i : null });
  }

  handlePanels(i) {
    this.setState({ openPanel: i }, function() {
      window.scrollTo(0, this.getDistance(this.panels.current) - 120);
    });
  }

  toggleMobileShare() {
    this.setState({
      openMobileShare: !this.state.openMobileShare
    });
  }

  handleResize() {
    if (window.innerWidth < 767) {
      if (!this.state.isMobile) {
        this.setState({
          currentCerosEmbed: `<div style='position: relative;width: auto;padding: 0 0 100%;height: 0;top: 0;left: 0;bottom: 0;right: 0;margin: 0;border: 0 none' id="experience-5f6a33ce58d0f" data-aspectRatio="1"><iframe allowfullscreen src='//view.ceros.com/inc/ibd2020-topart-mobile?heightOverride=355' style='position: absolute;top: 0;left: 0;bottom: 0;right: 0;margin: 0;padding: 0;border: 0 none;height: 1px;width: 1px;min-height: 100%;min-width: 100%' frameborder='0' class='ceros-experience' title='ibd2020-topart-mobile' scrolling='no'></iframe></div><script type="text/javascript" src="//view.ceros.com/scroll-proxy.min.js" data-ceros-origin-domains="view.ceros.com"></script>`,
          isMobile: true
        });
      }
    } else if (this.state.isMobile) {
      this.setState({
        currentCerosEmbed: `<div style='position: relative;width: auto;padding: 0 0 58.59%;height: 0;top: 0;left: 0;bottom: 0;right: 0;margin: 0;border: 0 none' id="experience-5f5fad762dd99" data-aspectRatio="1.70666667"><iframe allowfullscreen src='//view.ceros.com/inc/ibd2020-topart-desktop?heightOverride=1125' style='position: absolute;top: 0;left: 0;bottom: 0;right: 0;margin: 0;padding: 0;border: 0 none;height: 1px;width: 1px;min-height: 100%;min-width: 100%' frameborder='0' class='ceros-experience' title='ibd2020-topart-desktop' scrolling='no'></iframe></div><script type="text/javascript" src="//view.ceros.com/scroll-proxy.min.js" data-ceros-origin-domains="view.ceros.com"></script>`,
        isMobile: false
      });
    }
  }

  render() {
    const { fullPageAdvertorial } = this.props;

    const post = get(this.props.posts, '[0]', {});
    const ibdData = get(post, 'customFields.innovation_by_design');
    let primaryEntries = get(ibdData, 'list_data_1', []);
    let secondaryEntries = get(ibdData, 'list_data_2', []);
    let intro = [];
    const introGraphic = this.state.isMobile
      ? 'https://images.fastcompany.net/image/upload/v1601569311/fcweb/ibd-logo-small_lnvlvm.svg'
      : 'https://images.fastcompany.net/image/upload/v1601569311/fcweb/IBD_medium_jmyqok.svg';

    // Combine the article content into one text chunk.
    for (let x = 0; x < post.content.length; x++) {
      for (let y = 0; y < post.content[x].length; y++) {
        intro.push(post.content[x][y]);
      }
    }

    intro = intro
      .join('<!-- paragraph -->')
      .replace(/<!-- paragraph -->/g, '')
      .replace(/<p>/g, '')
      .replace(/<\/p>/g, '');

    const primaryPanelEntries = (
      <UserConsumer>
        {({ hasPaywallAccess }) => {
          const showDrawer = !hasPaywallAccess();
          if (showDrawer) {
            primaryEntries = [...primaryEntries].slice(0, 2);
          }
          return (
            <section className="list">
              {primaryEntries.map((entry, idx) => (
                <div className="list-entry panel1" key={`entry-${idx}`}>
                  {entry.article_url && (
                    <a
                      target="_blank"
                      href={entry.article_url}
                      className="full-entry-link"
                    >
                      {' '}
                    </a>
                  )}
                  {entry.award_image_url ? (
                    <React.Fragment>
                      <img
                        src={entry.award_image_url}
                        className="entry-image"
                        alt=""
                      />
                      <img
                        src={entry.award_image_url_hover}
                        className="entry-image-hover"
                        alt=""
                      />
                    </React.Fragment>
                  ) : (
                    <div style={{ width: '100%', height: '10px' }} />
                  )}

                  {entry.award && (
                    <div className="entry-category">
                      <span
                        className="award-circle"
                        style={{ backgroundColor: `${entry.award_color}` }}
                      />
                      {entry.award}
                    </div>
                  )}

                  {entry.company_name && (
                    <div className="entry-name">
                      <span
                        dangerouslySetInnerHTML={{ __html: entry.company_name }}
                      />
                      <div className="entry-button entry-button-mobile">
                        <img
                          src="https://images.fastcompany.net/image/upload/v1587746091/fc/arrow-icon-orange_eccnym.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  )}

                  {entry.reason && (
                    <div
                      className="entry-deck"
                      dangerouslySetInnerHTML={{ __html: entry.reason }}
                    />
                  )}

                  {entry.article_image_url && (
                    <React.Fragment>
                      <img
                        src={entry.article_image_url}
                        className="entry-article-image"
                      />
                      <img
                        src={entry.article_image_url_hover}
                        className="entry-article-image-hover"
                      />
                    </React.Fragment>
                  )}

                  {entry.company_description && (
                    <div
                      className="entry-copy"
                      dangerouslySetInnerHTML={{
                        __html: entry.company_description
                      }}
                    />
                  )}

                  {
                    <div className="entry-button">
                      <img
                        src="https://images.fastcompany.net/image/upload/v1587746091/fc/arrow-icon-orange_eccnym.svg"
                        alt=""
                      />
                    </div>
                  }
                </div>
              ))}
            </section>
          );
        }}
      </UserConsumer>
    );

    const secondaryPanelEntries = (
      <UserConsumer>
        {({ hasPaywallAccess }) => {
          const showDrawer = !hasPaywallAccess();
          if (showDrawer) {
            secondaryEntries = [...secondaryEntries].slice(0, 4);
          }
          return (
            <section className="">
              <div className="ibd-awards">
                {secondaryEntries.map((entry, idx) => (
                  <div
                    className="ibd-awards__entry"
                    key={`ibd-award-entry-${idx}`}
                  >
                    <div className="award__image">
                      <a target="_blank" href={entry.article_url}>
                        <img src={entry.image_url} alt="" />
                        <img
                          className="award__image__hover"
                          src={entry.image_url_hover}
                          alt=""
                        />
                      </a>
                    </div>
                    <a target="_blank" href={entry.article_url}>
                      <div
                        className="award__name"
                        dangerouslySetInnerHTML={{ __html: entry.award_name }}
                      />
                    </a>
                    <div
                      className="award__description"
                      dangerouslySetInnerHTML={{
                        __html: entry.award_description
                      }}
                    />
                  </div>
                ))}
              </div>
            </section>
          );
        }}
      </UserConsumer>
    );

    return (
      <React.Fragment>
        <div className="post post--default list-article ibd-list-article">
          <span
            className="ibd-header"
            dangerouslySetInnerHTML={{
              __html: `${this.state.currentCerosEmbed}`
            }}
          />
          <div className="post__main">
            <span className="ibd-social-desktop">
              <Social
                enableSticky={true}
                empty={false}
                topValue={fullPageAdvertorial ? 65 : 65}
                text={encodeURIComponent(get(post, 'title') || '')}
                uri={post.isNewUrl ? get(post, 'newUrl', '') : post.link}
                modifier="post"
                bottomBoundary={`#social--${post.id}`}
                encapsulateId={`social--${post.id}`}
                active={this.state.social}
                {...this.props}
              />
            </span>
            <div className={`post__container post__container--${post.layout}`}>
              <div className="post__content">
                <section
                  className="introduction"
                  style={{ marginTop: `${this.state.currentCerosHeight}` }}
                >
                  <p>
                    <a
                      href="https://kudos.fastcompany.com/shop/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={introGraphic}
                        style={{ float: 'left', marginRight: '15px' }}
                      />
                    </a>
                    <span dangerouslySetInnerHTML={{ __html: intro }} />
                  </p>
                </section>

                {true ? (
                  <React.Fragment>
                    <div
                      className={`panel-header ${
                        this.state.openMobileShare ? 'active' : ''
                      }`}
                    >
                      <div
                        className={`tab tab1 ${
                          this.state.openPanel === 0 ? 'active' : ''
                        }`}
                        onClick={() => {
                          this.handlePanels(0);
                        }}
                      >
                        {' '}
                        {ibdData.panel_name_1}{' '}
                      </div>
                      <div
                        className={`tab tab2 ${
                          this.state.openPanel === 1 ? 'active' : ''
                        }`}
                        onClick={() => {
                          this.handlePanels(1);
                        }}
                      >
                        {' '}
                        {ibdData.panel_name_2}{' '}
                      </div>
                      <div
                        className={`tab mobile-share ${
                          this.state.openPanel === 2 ? 'active' : ''
                        }`}
                        onClick={() => {
                          this.toggleMobileShare();
                        }}
                      >
                        <img
                          src="https://images.fastcompany.net/image/upload/v1587762424/fc/share_icon_k1ift6.svg"
                          alt="share"
                        />
                      </div>
                      <Social
                        enableSticky={false}
                        empty={false}
                        topValue={fullPageAdvertorial ? 160 : 111}
                        text={encodeURIComponent(get(post, 'title') || '')}
                        uri={
                          post.isNewUrl ? get(post, 'newUrl', '') : post.link
                        }
                        modifier="post"
                        bottomBoundary={`#social--${post.id}`}
                        encapsulateId={`social--${post.id}`}
                        active={this.state.social}
                        {...this.props}
                      />
                    </div>

                    <UserConsumer>
                      {({ hasPaywallAccess }) => {
                        const showDrawer = !hasPaywallAccess();

                        return (
                          <section className="panels" ref={this.panels}>
                            <div
                              className={`panel ${
                                this.state.openPanel === 0 ? 'active' : ''
                              }`}
                            >
                              {primaryPanelEntries}
                            </div>
                            <div
                              className={`panel ${
                                this.state.openPanel === 1 ? 'active' : ''
                              }`}
                            >
                              {secondaryPanelEntries}
                            </div>
                            {showDrawer && <BlueConicPremiumPaywall />}
                          </section>
                        );
                      }}
                    </UserConsumer>
                  </React.Fragment>
                ) : (
                  <section className="list-container">
                    {primaryPanelEntries}
                  </section>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ListArticle.defaultProps = {
  fullPageAdvertorial: true
};

ListArticle.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      customFields: PropTypes.shape({
        list_article: PropTypes.shape({
          copy_display_style: PropTypes.string,
          open_first_accordion: PropTypes.bool
        })
      })
    })
  ).isRequired,
  fullPageAdvertorial: PropTypes.bool
};
